
import * as React from "react"

import { graphql, Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'




import Layout from "../components/layout"
import Seo from "../components/seo"

const CategoryPage = ({pageContext, data}) => {
	


	const regs = (pageContext.title == "") ? `^[^\/]+$` :`^${pageContext.title}/\[^\/]+$`

	const filData: Array<any> = data.allFile.nodes

	console.log(filData)
	const mapData = filData.filter((thumb)=>{
			const reg = new RegExp(regs)
			return(reg.test(thumb.relativeDirectory))
		})

	const thumbs = mapData.map((arNode)=>(
		<div 
		className="mb-5 relative"
		style={{breakInside: "avoid"}}

		>
			<Link to={`/products/${arNode.relativeDirectory}`}>
			<GatsbyImage image={arNode.childImageSharp.gatsbyImageData} alt={arNode.relativeDirectory}

			
			/>
			<div className="bg-black text-gray-200 p-2 text-xs font-bold uppercase bg-opacity-70 absolute bottom-0 left-0 right-0">
				<h2>{(arNode.relativeDirectory as String).replace(/^.*\/(.*)$/, "$1").replace(/_/g," ")}</h2>
			</div>
			</Link>
		</div>
	) )



		return(

	<Layout>
		<h3 className="text-2xl py-6 font-bold uppercase text-gray-600">
			{ (pageContext.title == "") ? "Products" : (pageContext.title as String).replace(/^.*\/(.*)$/, "$1").replace(/_/g," ")}
		</h3>
	<div dangerouslySetInnerHTML={{__html: pageContext.desc}}> 
		
	</div>
	<div>
		<div id="infinite" className="gap-x-5 cc-2 md:cc-3 lg:cc-4 mt-5">
			{thumbs}

		</div>
	</div>
	
	

	
	
	</Layout>
)
}


export const query = graphql`
	query {
	allFile(filter: {
			sourceInstanceName: {eq: "products"},
			ext: {eq: ".jpg"},
			name:{eq: "thumb"}
			})
	{
		nodes {
		relativeDirectory
		childImageSharp {
      	  gatsbyImageData(
				width:300
				placeholder: BLURRED
			)
      	}
		}
	}
	}
	`
	
export default CategoryPage